.about{
display: flex;
flex-direction: column;
position: relative;
top: 3rem;

    &__top{
    display: flex;
    flex-direction: column;
    align-items: center;

        &--info{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;    
            width: 15rem;
            height: 15rem;
            border-radius: 50%;
            position: relative;
            top: 1rem;
            z-index: 0;
            background: linear-gradient(45deg,#fac000, #9af8ff, #6aff14, #fac000);
            animation: glowing 10s linear infinite;
            background-size: 400%;

            &--description{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5rem;    
                height: 0;
                color: #FFFFFF;
                z-index: 0;

                .react-logo{
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    position: relative;
                    top: 1.75rem;
                    left: 14.75rem;
                }

                .logo2{
                    width: 4rem;
                    height: 2.5rem;
                    border-radius: 10px;
                    position: relative;
                    top: 4.5rem;
                    left: 14.75rem;
                }

                .logo3{
                    width: 3rem;
                    height: 3rem;
                    position: relative;
                    top: 8rem;
                    left: 12.75rem;
                }

                .logo4{
                    width: 4rem;
                    height: 3rem;
                    position: relative;
                    top: 12rem;
                    left: 7rem;                 
                }

                .logo5{
                    width: 3rem;
                    height: 3rem;
                    border-radius: 50%;
                    position: relative;
                    top: 12.5rem;
                    left: -1rem;                         
                }

                .logo6{
                    width: 4rem;
                    height: 3rem;
                    position: relative;
                    top: 9.25rem;
                    left: -7.5rem;                               
                }

                .logo7{
                    width: 4rem;
                    height: 1.5rem;
                    border-radius: 5px;
                    position: relative;
                    top: 6.5rem;
                    left: -12rem;  
                }

                .logo8{
                    width: 5rem;
                    height: 1.5rem;
                    border-radius: 5px;
                    position: relative;
                    top: 4.5rem;
                    left: -15rem;
                    background-color: #FFFFFF;         
                }

                .logo9{
                    width: 2rem;
                    height: 2rem;
                    border-radius: 3px;
                    position: relative;
                    top: 2.25rem;
                    left: -17.75rem;              
                }
            }

            &--image{
                width: 15rem;
                height: 15rem;
                border-radius: 50%;
                object-fit: cover;
                object-position: 25% 0%;
                cursor: pointer;
                z-index: 1;
                
                &--disappear {
                    filter: blur(5px);
                    opacity: 0;
                    transition: opacity .6s ease-in-out;
                    width: 15rem;
                    height: 15rem;
                    border-radius: 50%;
                    object-fit:cover;
                    object-position: 25% 0%;
                    cursor: pointer;   
                }
            }
    
        }    

        &--name{
            color: #FFFFFF;
            animation-duration: 3s;
            animation-name: slidein;
            -webkit-box-reflect: below 0.1rem linear-gradient(transparent, rgba(0,0,0,.2));
        }
    }

    &__bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        margin-top: 1rem;

        a{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &--bates-logo{
            width: 5rem;
            height: auto;
        }

        &--bates-degree{
            margin: -1rem 0 0 0;
        }

        &--ahs-logo{
            width: 5rem;
            height: auto;
            margin: 1rem 0 1rem 0;
        }

        &--ahs-achievements{
            margin: -1rem 0 0 -2rem;

            li{
                list-style: none;
                text-align: center;
            }
        }
    }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    25% { background-position: 50% 0; }
    50% { background-position: 100% 0;}
    75% { background-position: 50% 0; }
    100% { background-position: 0 0; }
}

@keyframes slidein {
    0% {font-size: 1rem;}
    50% {font-size: 2.75rem;}
    100% {font-size: 2rem;}
}

@keyframes draw {
    0% {
      stroke-dashoffset: -2000;
      stroke: #FFC300;
    }
    30% {
      stroke: #FF5733;
    }
    50% {
      stroke: #FFC300;
    }
    70% {
      stroke: #FF5733;
    }
    100% {
    stroke-dashoffset:0;  
    stroke: #FFC300;
    }
}

@keyframes waviy {
0% {
    transform: translateY(0)
}
20% {
    transform: translateY(-20px)
}
40%{
    transform: translateY(0)
}
100% {
    transform: translateY(0)
}
}