.nav{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 2.3rem;
    background: linear-gradient(135deg,#fa0000, #ffffff, #142bff);
    z-index: 3;

    &__list{
        display: flex;
        font-weight: 600;

        &--item{
            position: relative;
            font-size: 1.25rem;
            list-style: none;
            color: #000000;
            margin: 0 1.5rem 0 0;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0%;
                border-bottom: 2px solid #000000;
                transition: 0.4s;
            }

            &:hover:after {
                width: 100%;
            }

            a{
                text-decoration: none;
                color: #000000;
            }
        }
    }
}