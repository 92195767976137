.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    margin: 1rem 0 0 0;

    &__heading{
        font-size: 2rem;
    }

    &__list{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: -1rem 0 3rem 0;

        &--item{
            list-style: none;
            margin: 0 0 0.75rem -2rem;
            font-size: 1.5rem;

            &--linkedin-link{
                display: flex;
                align-items: center;    
                text-decoration: none;
                color: #FFFFFF;
            }

            &--linkedin-logo{
                width: 1.5rem;
            }  
            
            &--gmail-logo{
                width: 3rem;
                position: relative;
                top: 0.15rem;
                margin-right: 0.25rem;
            }

            &--github-link{
                display: flex;
                align-items: center;    
                text-decoration: none;
                color: #FFFFFF;
            }

            &--github-logo{
                width: 1.4rem;
                margin: 0 0.2rem 0 0;
            }  
        }
    }
}