.projects{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    margin: 6rem 0 0 0;

    &__heading{
        font-size: 2rem;
    }

    &__frememberatr{
        display: flex;
        flex-direction: column;
        align-items: center;

        &--image{
            height: 16rem;
            width: 20rem;    
        }

        &--title{
            margin-top: 0rem;
        }

        &--description{
            margin: 0 2rem 3rem 2rem;
            text-align: center;
        }
    }
}